let request: IDBOpenDBRequest;
let db: IDBDatabase;
let version = 1;

import { Stores } from './../components/_models/enums/stores';

export interface OfflineData {
  key: string;
  value: string;
}

export const initDB = (): Promise<boolean> => {
  return new Promise(async (resolve) => {
    // open the connection
    request = indexedDB.open('sitOfflineDB');

    request.onupgradeneeded = () => {
      db = request?.result;
      if (!db?.objectStoreNames.contains(Stores.Visit)) {
        db.createObjectStore(Stores.Visit, { keyPath: 'key' });
      }

      if (!db?.objectStoreNames.contains(Stores.PreVisit)) {
        db.createObjectStore(Stores.PreVisit, { keyPath: 'key' });
      }
    };

    request.onsuccess = () => {
      resolve(true);
    };

    request.onerror = () => {
      resolve(false);
    };
  });
};

export const addData = <T>(storeName: string, data: T): Promise<T | string | null> => {
  return new Promise((resolve) => {
    request = indexedDB.open('sitOfflineDB', version);

    request.onsuccess = (e) => {
      if (request.readyState == 'done') {
        db = request?.result;
        const tx = db.transaction(storeName, 'readwrite');
        if (Array.isArray(data)) {
          data.forEach((item) => {
            const store = tx.objectStore(storeName);
            store.put(item);
          });
        } else {
          const store = tx.objectStore(storeName);
          store.put(data);
        }
        resolve(data);
      }
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const updateData = <T>(storeName: string, data: T): Promise<T | string | null> => {
  return new Promise((resolve) => {
    request = indexedDB.open('sitOfflineDB', version);

    request.onsuccess = () => {
      db = request?.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.put(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const findStoreData = <T>(storeName: Stores, key: string | number): Promise<T[]> => {
  return new Promise((resolve) => {
    request = indexedDB!.open('sitOfflineDB');
    request!.onsuccess = (event: any) => {
      db = event.target.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      let res: any = null;
      if (key == 'all') {
        res = store.getAll();
        res.onsuccess = () => {
          resolve(res.result);
        };
      } else {
        res = store.get(key);
        res.onsuccess = () => {
          resolve(res.result);
        };
      }
    };
  });
};

export class OfflineServices {
  // async init() {
  //   try {
  //     return await initDB();
  //   } catch (error) {
  //     console.error(`Error fetch overview`, error);
  //     throw error;
  //   }
  // }

  // async offline(path: string, params: any = {}) {
  //   try {
  //     let data: any = null;
  //     const status = await initDB();
  //     if (status) {
  //       const res: any = await findStoreData<OfflineData>(Stores.Data, path);
  //       if (res) {
  //         data = res?.value;
  //       }
  //     }
  //     return { data: data };
  //   } catch (error) {
  //     console.error(`Error fetch overview`, error);
  //     throw error;
  //   }
  // }

  async get(table: Stores, key: string | number | undefined = '') {
    try {
      if (table && key) {
        const status = await initDB();
        if (status) {
          let store: any = await findStoreData<OfflineData>(table, key);
          if (store) {
            if (key == 'all') {
              let res = new Array();
              store?.map((item: any) => {
                res.push(item.value);
              });
              return res;
            } else {
              return store?.value;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async store(table = Stores.Data, data: any = {}, key = '') {
    try {
      const status = await initDB();
      if (status) {
        await addData(table, { key: key, value: data });
      }
      //   const status = await initDB();
      //   if (status && path) {
      //     const res: any = await addData(Stores.Data, { key: path, value: data });
      //     if (res) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      //   return false;
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async update(table = Stores.Data, data: any = {}, key: string) {
    try {
      const status = await initDB();
      if (status) {
        await updateData(table, { key: key, value: data });
      }
      //   const status = await initDB();
      //   if (status && path) {
      //     const res: any = await addData(Stores.Data, { key: path, value: data });
      //     if (res) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      //   return false;
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }
}
