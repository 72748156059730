export enum Stores {
  Data = 'data',
  Visit = 'visit',
  PreVisit = 'preVisit',
  Provinces = 'provinces',
  Districts = 'districts',
  SubDistrict = 'subDistricts',
  Menu = 'menu',
  Organization = 'organization',
  SelectedOrg = 'selectedOrg',
  ToDoList = 'toDoList',
  ListCustomerOffPlanFilter = 'listCustomerOffPlanFilter',
  ListOfCustomer = 'listOfCustomer',
  CustomerDetail = 'customerDetail',
  CustomerNote = 'customerNote',
  CustomerLocationByCompany = 'customerLocationByCompany',
  PlanByMonthAndYear = 'planByMonthAndYear',
  CustomerPrevisit = 'customerPrevisit',
  CustLegalPerson = 'custLegalPerson',
  CustomerAttribute = 'customerAttribute',
  CustomerShopType = 'customerShopType',
}
