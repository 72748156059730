String.prototype.parseToNumber = function () {
  let numString = this.replace(/,/g, '');
  if (numString.split('.').length > 2) {
    numString = numString.split('.').splice(0, 2).join('.');
  }
  if (numString[0] == '.') {
    numString = '0' + numString;
  }
  return Number(numString);
};
