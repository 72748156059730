import { Dialog, Transition } from '@headlessui/react';
import { forwardRef, Fragment, MutableRefObject, ReactNode } from 'react';
import { PiCheckCircleBold, PiInfoBold, PiTrashBold, PiWarningCircleBold, PiXCircleBold } from 'react-icons/pi';
import { XMarkIcon } from '@heroicons/react/24/outline';
interface Props {
  children?: ReactNode;
  footer?: ReactNode;
  title?: ReactNode;
  style?: 'success' | 'info' | 'warning' | 'error' | 'delete' | undefined;
  show: boolean;
  onClose?: () => void;
  disabledDone?: boolean;
  isStatic?: boolean;
  isAlert?: boolean;
}

const DialogModal = forwardRef<MutableRefObject<HTMLElement | null>, Props>(({ children, footer, title, style, show = false, onClose, isStatic = false, isAlert = false, disabledDone = false, ...rest }, ref) => {
  const icons = () => {
    if (style) {
      let icon = <></>;
      switch (style) {
        case 'success':
          icon = <PiCheckCircleBold className="w-24 h-24 text-light-success" />;
          break;
        case 'info':
          icon = <PiInfoBold className="w-24 h-24 text-light-info" />;
          break;
        case 'warning':
          icon = <PiWarningCircleBold className="w-24 h-24 text-light-warning" />;
          break;
        case 'error':
          icon = <PiXCircleBold className="w-24 h-24 text-light-error" />;
          break;
        case 'delete':
          icon = (
            <div className="rounded-full w-20 h-20 p-2 border-8 border-light-error">
              <PiTrashBold className="w-full h-full text-light-error" />
            </div>
          );
          break;
      }

      return <div className="flex items-center justify-center">{icon}</div>;
    }
    return <></>;
  };
  return (
    <Transition show={show} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-75" leaveFrom="opacity-100" leaveTo="opacity-0">
      <Dialog initialFocus={ref as MutableRefObject<HTMLElement | null>} as="div" onClose={() => (onClose && !isStatic ? onClose() : {})} static={isStatic} className={'fixed inset-0 overflow-y-auto z-30 dialog'}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>
        <div className="fixed inset-0 ">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full  max-w-md transform rounded-2xl bg-light-neutral-800 dark:bg-dark-neutral-600 border-light-neutral-700 dark:border-dark-neutral-500 text-left align-middle shadow-xl transition-all">
                {title != undefined || onClose ? (
                  <div className="px-4 pb-4 pt-6 xs:px-6 xs:pb-6 xs:pt-8">
                    {icons()}
                    <div className="flex items-center justify-center">
                      {title != undefined ? <Dialog.Title className="w-full text-center label-subtitle3 xs:label-subtitle1">{title}</Dialog.Title> : <div className="w-full"></div>}
                      {onClose && isStatic && !isAlert ? (
                        <button className="" onClick={onClose}>
                          <XMarkIcon className="w-5 h-5 xs:w-6 xs:h-6 text-light-neutral-200 dark:text-dark-neutral-200 hover:text-light-neutral-300 dark:hover:text-dark-neutral-300" />
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mt-2 xs:mt-4"></div>
                )}

                <div className="pb-6 px-4 xs:pb-6 xs:px-6">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default DialogModal;
