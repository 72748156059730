/**
 * Rectrive token from local storage and validate token
 * @returns
 */
export const checkToken = () => {
  let token: any = null;

  token = localStorage.getItem('accessToken');

  let code = 0;
  if (token == null || token === '') {
    code = 1;
  } else {
    let jwt = token.split('.');
    if (jwt.length != 3) {
      code = 2;
    } else {
      //check expire
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c: any) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      let payLoad = JSON.parse(jsonPayload);
      let d = new Date();
      let t = d.getTime() / 1000;
      if (t > payLoad.exp) {
        code = 3;
      }
    }
  }
  return code;
};

export const checkTokenAsync = async () => {
  return checkToken();
};
