interface FormatWithCommaOptions {
  digits?: number;
  prefix?: string;
  suffix?: string;
  empty?: string;
  infinity?: string;
  zero?: string;
}

Number.prototype.formatWithComma = function ({ digits = -1, prefix = '', suffix = '', empty = '-', zero = '0', infinity = '0' }: FormatWithCommaOptions = {}) {
  if (this == null || this === undefined || isNaN(Number(this))) {
    return empty;
  }
  if (this == 0) {
    return zero;
  }
  if (this == Infinity) {
    return infinity;
  }
  const numberString = digits >= 0 ? this.toFixed(digits).toString() : this.toString();
  const [v, d] = numberString.split('.');
  const formattedNumber = v.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (d != undefined ? `.${d}` : '');
  return prefix + formattedNumber + suffix;
};

export const anyToNumber = (value: any) => {
  if (value === 'undefined' || value === undefined || value === 'null' || value == null) {
    return '';
  }
  const num = typeof value === 'number' ? value : Number(value);
  if (isNaN(num)) return 0;
  return num;
};

export const numberIsZero = (value1: any, value2: any) => {
  if (!value1 && !value2) {
    return true;
  }

  if (String(value1) == '0' && String(value2) == '0') {
    return true;
  }
  return false;
};

export const numberWithCommas = (value: any, empty = '-', zero = '0') => {
  if (value !== undefined && value != null) {
    const num = typeof value === 'number' ? value : Number(value);

    if (isNaN(num)) {
      return value;
    }

    if (value == 0 || value == '0') {
      return zero;
    }

    const formattedValue = num.toLocaleString();
    return formattedValue;
  }
  return empty;
};

export const prepending = (num: number) => {
  return (num < 10 ? '0' : '') + num;
};
