import { forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: '' | 'success' | 'info' | 'warning' | 'error';
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

const OutlinedButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, type = 'button', color = '', onClick = () => {}, disabled = false, loading = false, className, fullWidth = true, ...rest } = props;
  return (
    <button
      //
      {...rest}
      ref={ref}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      className={twMerge('button relative outlined', color, className, fullWidth ? 'w-full' : '')}>
      {loading && <span className="absolute left-4 h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-light-neutral-200 dark:border-dark-neutral-200 border-4" />}
      {children && children}
    </button>
  );
});

export default OutlinedButton;
