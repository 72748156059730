import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import th from './locales/th.json';

i18n.use(initReactI18next).init({
  resources: {
    th: {
      translation: th,
    },
  },
  lng: 'th',
  supportedLngs: ['th'],
  interpolation: {
    escapeValue: false,
  },
  nonExplicitSupportedLngs: true,
});
