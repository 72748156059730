import { checkToken } from '@/middleware/token_manager';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { alert } from '@/components/modals/alert';
import * as Sentry from '@sentry/browser';

/**
 * ✅🔼 Define what to do to axios requst
 * - set bearer auth token
 * - set content type
 * @param config
 * @returns
 */
export const reqInterceptor = (config: AxiosRequestConfig) => {
  config.baseURL = process.env.REACT_APP_API_URL;
  if (config.headers) {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
    if (config.headers['Content-Type'] === undefined || config.headers['Content-Type'] === null || config.headers['Content-Type'] === false) {
      config.headers['Content-Type'] = 'application/json, text/plain, */*';
    }
    return config;
  }
  return config;
};

/**
 * ❌🔼 Denied when request error
 * @param error AxiosError that cause this interception
 */
export const reqErrorInterceptor = (error: AxiosError) => {
  Sentry.captureException(error);
  Promise.reject(error);
};

/**
 * ✅🔽Define what to do with axios response
 * @param response
 * @returns
 */
export const resInterceptor = async (response: AxiosResponse) => {
  return response;
};

/**
 * ❌🔽Define what to do when axios throw error
 * - ignore error from DOCAPI
 * - direct no response to error message
 * - 404 alert info from displayMessage
 * - 401 direct to error message and kick to login
 * @param error Axios error object
 * @returns
 */

export const errInterceptor = async (error: AxiosError) => {
  const version = localStorage.getItem('version');
  /**
   * Force Ignore error request from sit DOC api
   */
  if (error.request.responseURL.search('DocsAPIx64') > 0) {
    console.warn('DOC error ignore', error.request.responseURL);
    return Promise.reject(error);
  }

  Sentry.withScope(function (scope) {
    const req = error;
    console.error(error);
    // scope.setFingerprint([req.config.method, req?.config?.url, req?.code, req?.response?.status]);
    // scope.setContext(error?.request)
    Sentry.captureException(error);
  });
  /**
   * Handle error without respone
   */
  if (!error.response) {
    await checkAccessToken();
    console.error('Axios error without response!');
    return Promise.reject(error);
  }
  /**
   * Handle error with 404
   */
  if (error.response.status === 404) {
    const resp: any = error.response.data;
    //display error dialog for v1
    if (resp?.displayMessage) {
      console.warn('error with message', resp);
      // store.dispatch(LoadingAction.setLoaded());
    }
    if (version == '2') {
      return Promise.reject(error);
    } else {
      console.warn('404 by pass to resolve null');
      return Promise.reject(error);
    }
  }
  /**
   * Handle 401 error and redirect to login
   */
  if (error.response.status === 401) {
    // store.dispatch(LoadingAction.setLoaded());
    await checkAccessToken();
    gotoLogin();
  }

  return Promise.reject(error);
};

/**
 * Redirect to app login
 */
const gotoLogin = () => {
  if (window?.androidWebview) window.androidWebview.appLogin();
};

/**
 * Check access token code to display error message
 */
export const checkAccessToken = async () => {
  let code = checkToken();
  if (code === 1) {
    await alert.error('ไม่มีสิทธิ์เข้าใช้งาน!', 'ไม่พบข้อมูลโทเคน \nเมื่อกดปิดจะกลับไปที่หน้าเข้าสู่ระบบ.', {
      confirm: 'ปิด',
    });
    gotoLogin();
  } else if (code === 2) {
    await alert.error('โทเคนไม่ถูกต้อง!', 'โทเคนไม่สามารถใช้งานได้ \nเมื่อกดปิดจะกลับไปที่หน้าเข้าสู่ระบบ.', {
      confirm: 'ปิด',
    });
    gotoLogin();
  } else if (code === 3) {
    await alert.error('โทเคนหมดอายุ!', 'โทเคนไม่สามารถใช้งานได้ \nเมื่อกดปิดจะกลับไปที่หน้าเข้าสู่ระบบ.', {
      confirm: 'ปิด',
    });
    gotoLogin();
  } else {
    await alert.error('พบข้อผิดพลาด', 'Network error please try again.', {
      confirm: 'ปิด',
    });
  }
};

/**
 * Axios with no default config for external request eg. Longdo API
 */
export const defaultAxios = axios.create({});
