//Data
import { initDB } from './../../utils/offline';

// import overview from './../data/overview.json';
// import organization from './../data/organization.json';
// import customer from './../data/customer.json';
// import toDo from './../data/todo.json';
// import syncData from './../data/syncData.json';
import { getAllCacheData} from '@/database/init';

let offlineMasterData:any = {}
export class OfflineIndex {
  

  async initOfflineDB() {
    offlineMasterData = await getAllCacheData()
    console.log("get offline data from cache", offlineMasterData)
    await initDB();
  }

  async getSyncData() {
    offlineMasterData = await getAllCacheData()
    await this.store('offline_syncData', JSON.stringify(offlineMasterData?.syncData));
    return offlineMasterData?.syncData;
  }

  async loadOfflineSaleOrg() {}

  async getUserLogin() {
    let menu = offlineMasterData?.overview?.data?.menu;
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        userLogin: {
          contactId: 0,
          employeeId: '',
          prefixCode: '',
          localFirstName: '',
          localLastName: '',
          postNameThai: '',
          genderCode: '',
          defaultRegionId: 0,
        },
        roleItems: [],
        menuItems: menu,
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getSaleOrgs() {
    await this.getSyncData();
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: null,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getTodayActivity() {
    offlineMasterData = await getAllCacheData()
    return offlineMasterData?.overview?.data?.todayActivityItems;
  }

  async getOrganization() {
    offlineMasterData = await getAllCacheData()
    console.log("organization cahce", offlineMasterData?.organization)
    return offlineMasterData?.organization?.data.organization;
  }

  async getCustomer() {
    offlineMasterData = await getAllCacheData()
    console.log("..........customer data", offlineMasterData?.customer)
    let cust: any = offlineMasterData?.customer;
    return cust?.data;
  }

  async getSurvey() {
    offlineMasterData = await getAllCacheData()
    let cust: any = offlineMasterData?.customer;
    return cust?.data?.allSurveyData;
  }

  async getPlans() {
    offlineMasterData = await getAllCacheData()
    let plans: any = offlineMasterData?.toDo;
    return plans?.data?.planByMonthAndYear;
  }

  async store(keyName: string, payload: string) {
    await localStorage.setItem(keyName, payload);
  }

  async get(keyName: string) {
    let data = localStorage.getItem(keyName);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  async fetchWithOrg(data: any) {
    let selectedOrg = await this.get('offline_selectedOrg');
    let SelectedSaleOrgCriteriaId = selectedOrg.SelectedSaleOrgCriteriaId;
    if (Array.isArray(data)) {
      let result = new Array();
      await Promise.all(
        data?.map((item: any) => {
          if (item.saleOrgCriteriaid == SelectedSaleOrgCriteriaId || item.saleOrgCriteriaId == SelectedSaleOrgCriteriaId) {
            result = item?.data;
          }
        })
      );
      return result;
    } else {
      if (data.saleOrgCriteriaid == SelectedSaleOrgCriteriaId) {
        return data?.data;
      } else {
        return null;
      }
    }
  }

  async fetchWithOrgIn(data: any, findKeyName: string) {
    let selectedOrg = await this.get('offline_selectedOrg');
    let SelectedSaleOrgCriteriaId = selectedOrg.SelectedSaleOrgCriteriaId;
    console.log('findKeyName data', data);
    console.log('findKeyName', findKeyName);
    console.log('SelectedSaleOrgCriteriaId', SelectedSaleOrgCriteriaId);
    let result = new Array();
    await Promise.all(
      data?.map((item: any) => {
        if (item[findKeyName].includes(SelectedSaleOrgCriteriaId)) {
          result.push(item);
        }
      })
    );
    console.log('fetchWithOrgIn', result);
    return result;
  }

  async fetchList(data: any, findKeyName: string, findValue: any) {
    let result = null;
    await Promise.all(
      data?.map((item: any) => {
        if (item[findKeyName] == findValue) {
          result = item;
        }
      })
    );
    return result;
  }

  async uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
