export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function isNotEmpty(value: any) {
  return !isEmpty(value);
}

export function isEmpty(value: any) {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
}

export function isString(value: any) {
  return typeof value === 'string' || value instanceof String;
}

export function mergeObjects(obj1: any, obj2: any): any {
  const result = {
    ...obj1,
  };

  for (const key in obj2) {
    if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key]) && obj2[key] !== null) {
      result[key] = mergeObjects(result[key], obj2[key]);
    } else {
      result[key] = obj2[key];
    }
  }

  return result;
}

export function copyArray(arr: any[]): any {
  return [...arr].map((item) => ({ ...item }));
}

export function displayName(first?: String, last?: String, nick?: String, empty?: String): String {
  const f: String = first ? first : '';
  const l: String = last ? last : '';

  const fullName: String = `${f} ${l}`.trim();
  const nickName: String = nick ? nick : '';

  if (isEmpty(fullName) && isEmpty(nickName)) {
    return empty ?? '';
  } else if (!isEmpty(fullName) && !isEmpty(nickName)) {
    return `${fullName} (${nickName})`.trim();
  } else if (!isEmpty(fullName)) {
    return fullName;
  } else {
    return nickName;
  }
}

export function convertToInitials(fullName: string): string {
  const words = fullName.split(' '); // Split full name into words
  const initials = words.map((word) => word.charAt(0).toUpperCase()); // Get the first character of each word and convert it to uppercase
  return initials.join(''); // Concatenate the initials together
}

export function isPathRoot() {
  const hash = window.location.hash;
  return hash.replace('#/', '').split('/').length < 2;
}

export const numberIsZero = (value1: any, value2: any) => {
  if (!value1 && !value2) {
    return true;
  }

  if (String(value1) == '0' && String(value2) == '0') {
    return true;
  }
  return false;
};

export function currentCompany() {
  let companyString = localStorage.getItem('orgCompany') ?? '';

  return {
    full: companyString,
    code: companyString?.substring(0, 4),
  };
}
